<template>
	<div class="">
		<div class="app-header d-lg-none" v-if="this.$store.state.currentChatId">
			<div class="bold d-sm-none">
				<div @click="chatBtnBackClick" class="msg-title my-1 h4 pointer">
					<strong><i class="bi bi-chevron-left me-3 bold" style="font-weight: 800;"></i></strong> 
					{{`${chatRecipient?.name}`}}
				</div>
			</div>
		</div>
		<div class="row">
            <div class="col-12 col-lg-4 d-none d-md-block p-4" id="userPanel">
                <div class="row">
					<div class="col-12 mb-4 pointer" @click="$router.go(-1)">
						<i class="bi bi-chevron-left"></i> Go back
					</div>
					<div class="col-12">
						<input class="form-control" placeholder="Search users..." />
					</div>
					<div class="col-12 my-5 text-muted" v-if="!Array.isArray(messages)" align="center">
						<div class="card card-body my-2" v-for="index in 5" :key="index">
							<div class="row">
								<div class="col-9">
									<Skeletor height="15" pill/>
								</div>
								<div class="col-3">
									<Skeletor height="15" pill/>
								</div>
							</div>
						</div>
					</div>	
					<div class="col-12 my-5 text-muted" v-else-if="Array.isArray(messages) && messages.length < 1" align="center">
						<em><i class="fas fa-comment-alt"></i> No chats found...</em>
					</div>	
					<div class="col-12 my-3 user-panel" v-else>
						<div class="row">
							<div class="col-12 my-2" v-for="msg in messages" :key="msg.id">
								<div class="pointer card" :class="(msg.id==currentChatId) ? 'msg-highlight' : ''" @click="messageClick(msg)">
									<div class="card-body ">
										<p class="card-title msg-date text-muted my-1">
											{{ formatDate(msg.updated, "dd/MM/yyyy") }}
										</p>
										<p class="card-title msg-title" :class="(!msg.read.includes(userProfile.id)) ? 'bold' : ''" style="font-weight:500;">
											{{ msg.users_info.find((user) => user.id !== userProfile.id).name }}
										</p>
										<p class="card-text msg-excerpt small text-secondary" :class="(!msg.read.includes(userProfile.id)) ? 'bold' : ''">
											<i class="bi bi-check2-circle" v-if="showReadIcon({ msg: msg.messages[msg.messages.length - 1], readList: msg.read })"></i> {{ (msg.messages[msg.messages.length - 1].meta == 'image') ? '[Image File Sent]': msg.messages[msg.messages.length - 1].content }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
            </div>
            <div class="col-12 col-lg-8 message-panel d-none d-md-block p-3" id="msgPanel" >
				<div class="row">
					<div class="col-12 message-pane mb-1">
						<div class="alert alert-info" align="center" v-if="showAlert"><i class="bi bi-exclamation-triangle-fill"></i> This chat is not end-to-end encrypted</div>
						<div class="row card-body" id="msg-content">
							<div class="col-12" v-for="(msg, index) in currentChat" :key="index">
								<div class="row">
									<div class="col-8 my-2 card-body1" :class="(msg.id === userProfile.id) ? 'right-msg-bubble bg-secondary text-white offset-4' : 'left-msg-bubble bg-white text-dark'">
										<p v-if="!msg.meta || msg.meta!='image'" class="my-2">
											{{msg.content}}
										</p>
										<!-- <ViewProfile v-if="msg.meta && msg.meta=='profile' && msg.id!==userProfile.id" :id="msg.id" /> -->
										<div v-if="msg.meta && msg.meta=='image'" class="my-3 pointer chat-card-img-container" data-toggle="modal" data-target="#viewImageModal" @click="imgFile = `https://soca-islands.imgix.net/${msg.content}?q=50&auto=format`">
											<img :data-src="`https://soca-islands.imgix.net/${msg.content}?q=50&auto=format`"
											:src="`https://soca-islands.imgix.net/${msg.content}?w=500&h=500&fit=crop&crop=entropy&px=8&blur=250&fm=webp`" class="card-img" />
										</div>
										<div align="right" style="font-size:10px;" class="small my-2">
											{{ formatDate(msg.created, "MMM dd ''yy HH:mm a") }}
											<i class="bi bi-check2-circle" v-if="showReadIcon({msg, readList: activeChat.read })"></i>
										</div>
									</div>
								</div>
							</div>			
						</div>
					</div>
					<div class="col-12 py-2 fixed-bottom bg-white">
						<div class="row mb-1 border border-0">
							<div class="col-12">
								<div class="input-group bg-white">
									<textarea :disabled="activeChat.length < 1" rows="2" class="form-control border" style="resize: none; font-size: 1.1rem;" placeholder="Message..." @input="textAreaEdit; txtMessage = $event.target.value" v-model="txtMessage" id="txtMessage" autocomplete="off"/>
									<button :disabled="activeChat.length < 1" class="btn btn-link text-reset" v-show="!txtMessage">
										<i class="bi bi-image h3"></i>
									</button>
									<button class="btn btn-dark text-reset1" @click="btnSendMessageClick" v-show="txtMessage">
										<i class="bi bi-send"></i> <strong>Send</strong>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
import { doc, updateDoc, arrayUnion } from "firebase/firestore"; 
import { db, collections } from "../firebaseConfig";
import { formatDate } from "../utils";
export default {
	name: "AppMessages",
	components: {
	},
	computed:{
		messages(){
			return this.$store.state.messages;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		currentChat(){
			if(Array.isArray(this.messages)){
				const msgs = this.messages.find(msg => msg.id === this.$store.state.currentChatId);
				if(msgs) {
					this.$nextTick(()=>{this.scrollMessagePane()})
					return msgs.messages;
				}else{
					return [];
				}
			}
		},
		isFormValid(){
			if(!this.txtMessage){
				return false;
			}

			return true;
		},
	},
	data(){
		return{
			formatDate,
			isBusy: false,
			imgData: { imgFile: "", imgFileName: "" },
			activeChat:[],
			currentChatId: "",
			txtMessage: "",
			chatRecipient: "",
			chatToken: "",
			imgFile: "",
			showAlert: false,
			chatDocRef: null,
		}
	},
	methods:{
		showReadIcon(msgData){
			const { msg, readList } = msgData;
			const isRead = readList.filter((user) => user !== this.userProfile.id)?.length;
			return msg.id === this.userProfile.id && isRead > 0;
		},
		calcHeight(value) {
			const numberOfLineBreaks = (value.match(/\n/g) || []).length;
			const newHeight = 62 + numberOfLineBreaks * 20;
			return (newHeight > 200 ? 200 : newHeight);
		},
		textAreaEdit(){
			const elmnt = document.getElementById("txtMessage");
			elmnt.style.height = "1px";
			const newHeight = Number(30 + elmnt.scrollHeight);
			elmnt.style.height = newHeight > 200 ? "200px" : `${newHeight}px`;
		},
		async messageClick(msg){
			try{
				this.showAlert = true;
				this.currentChatId = msg.id;
				this.activeChat = msg;
				this.chatRecipient = msg.users_info.find(user => user.id !== this.userProfile.id);
				document.getElementById("userPanel").classList.add("d-none");
				document.getElementById("msgPanel").classList.remove("d-none");
				this.$nextTick(()=>{this.scrollMessagePane()});
				this.$store.commit("setCurrentChatId", msg.id);
				this.$store.commit("setCurrentChat", msg);

				this.chatDocRef = doc(db, collections.MESSAGES, msg.id);
				await updateDoc(this.chatDocRef, {
					read: arrayUnion(this.userProfile.id),
				});
			}catch(error){
				console.log(error);
			}
		},
		displayName(arr){
			const found = arr.find(user => user.id !== this.userProfile.id);
			return found.name;
		},
		async btnSendMessageClick(){
			if(!this.txtMessage){
				return false;
			}
			try{
				const updated = Math.floor(Date.now()/1000)
				const message = {
					content: this.txtMessage,
					created: updated,
					id: this.userProfile.id,
					read:[]
				}
				await updateDoc(this.chatDocRef,{
					updated: updated,
					messages: arrayUnion(message),
					read:[this.userProfile.id]
				});
				this.txtMessage = "";
				/* .then(()=>{
					if(fb.firebase.messaging.isSupported()) this.sendNotification(this.txtMessage)
					this.txtMessage = ""	
				}) */
			}catch(error){
				console.log(error);
			}
		},
		scrollMessagePane(){
			let elmnt = document.getElementById("msg-content")
			elmnt.scrollIntoView(false) // Bottom
		},
		chatBtnBackClick(){
			this.currentChatId = "";
			this.$store.commit("setCurrentChatId", "")
			document.getElementById("userPanel").classList.remove("d-none")
			document.getElementById("msgPanel").classList.add("d-none")
		},
		handleImageUpload(data){
			this.imgData = data
			let updated = Math.floor(Date.now()/1000)
			let message = {
					content: this.imgData.imgFileName,
					created: updated,
					id: this.userProfile.id,
					meta:"image",
					read:[]
				}
			fb.messageCollection.doc(this.currentChatId).update({
				updated: updated,
				messages: fb.firebase.firestore.FieldValue.arrayUnion(message),
				read:[this.userProfile.id]
			}).then(()=>{
				if(fb.firebase.messaging.isSupported()) this.sendNotification("[Image File]")
				this.txtMessage = ""
			})
		},
		async sendNotification(msg){
			let token = await fb.fcmtokensCollection.doc(this.chatRecipient.id).get().then((doc)=>{
				if(doc.exists){
					return doc.data().fcmToken
				}else{
					return false
				}
			})

			if(token){
				var key = "AAAABDYjEOY:APA91bHM6LZZvNDE855XhydeNFN6AyDDYB57Wt9M5xVsdkuc3-zL1vMcIqGKVnADxEnlPhJnl4yc12UJyiVyKwS2NRzzuyZ0FZtZNG4EI9zmY5Z8zrpazD5p94lKaoXzM58AJWXZf2eR"
				var to = token
				var notification = {
					"title": `${this.userProfile.fname} sent a message.`,
					"body": msg,
					"icon": "https://socaislands.com/wp-content/uploads/2019/11/socaislands-png-logo.png",
					"click_action": "https://app.socaislands.com/messages"
				}

				fetch("https://fcm.googleapis.com/fcm/send", {
					"method": "POST",
					"headers": {
						"Authorization": "key=" + key,
						"Content-Type": "application/json"
					},
					"body": JSON.stringify({
						"notification": notification,
						"to": to
					})
				}).then(()=> {
					//console.log(response)
				}).catch((error)=> {
					console.error(error)
				})
			}
		},
	},
	mounted(){
		this.$store.commit("setPageTitle", "chats")
		if(this.$store.state.currentChatId){
			document.getElementById("userPanel").classList.add("d-none")
			document.getElementById("msgPanel").classList.remove("d-none")
			this.activeChat = this.$store.state.currentChat
			this.chatRecipient = this.activeChat.users_info.find(user => user.id === this.userProfile.id)
			this.$store.dispatch("loadImages")
		}else{
			document.getElementById("userPanel").classList.remove("d-none")
			this.$store.commit("setCurrentChatId", "")
		}
	},
	beforeDestroy(){
		this.$store.commit("setCurrentChatId", "")
	},
}
</script>
